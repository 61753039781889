@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}


.login{height:100vh;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:hidden}.login__container{display:block;margin-top:30px;margin-left:auto;margin-right:auto;height:400px;width:400px;padding:30px;text-align:center;background-color:#ffff;box-shadow:1px 4px 8px 1px rgba(0,0,0,0.1)}.login__btn{margin-top:20px}.login__btn a{color:#333;font-size:12px;text-decoration:none}.login__btn h5{margin-top:10px;color:red;font-size:12px;text-decoration:none;font-weight:400}.login__btn a:hover{color:#53d5d5}

.navbar{width:100%;height:80px;background-color:#fff;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.navbar h2{padding:15px 16px}.navbar span{color:#53d5d5}

.input__container{font-family:"Poppins",sans-serif}.input,select{width:100%;height:60px;padding:0 12px;font-family:"Lato",sans-serif;font-size:16px;border:1px solid #b8b8b8;border-radius:10px;color:#1A171B;background-color:#F2F3FA}input:-ms-input-placeholder{padding:0 12px;font-size:16px;font-family:"Lato",sans-serif;color:rgba(114,113,113,0.796078)}input::placeholder{padding:0 12px;font-size:16px;font-family:"Lato",sans-serif;color:rgba(114,113,113,0.796078)}input:focus{padding:0 12px;font-size:16px;font-family:"Lato",sans-serif;color:#1A171B}

button{height:40px;width:100%;font-family:"Poppins",sans-serif;font-size:12px;cursor:pointer;color:#ECECED;background-color:#6100DD;border:none;border-radius:10px;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2)}button:hover{background-color:#53d5d5}

.dashboard{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.dashboard h2{padding:50px 30px 0;font-size:30px}.dashboard span{color:#53d5d5}.dashboard h4{padding:10px 30px;font-size:16px}.dashboard p{padding:0px 30px 0}.dashboard .filter-period{padding-left:30px}.dashboard__info{padding:15px 30px;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start}.dashboard__graph-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.dashboard__graph{width:510px;height:300px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.dashboard__card-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.dashboard__card{width:350px;height:220px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.dashboard__graph-title h5{margin-bottom:5px;font-size:16px;font-weight:600}.dashboard__table-container{padding:20px 30px}.dashboard__table{width:100%;height:400px;padding:10px;background-color:#fff;border-radius:20px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.dashboard__table-container h3{margin-bottom:5px;font-weight:400}

.sidebar{position:fixed;left:0;width:250px;height:100%;overflow:scroll;background-color:#ffff;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.sidebar header{margin:16px;line-height:35px;text-align:left;font-family:"Poppins",sans-serif;font-size:15px}.sidebar__dot{color:#53d5d5}.sidebar h5{font-size:16px;font-weight:400;color:rgba(114,113,113,0.796078)}.sidebar ul a{display:block;height:100%;width:100%;padding-left:16px;color:#3F3D3D}.sidebar ul a span{margin-left:10px}ul li:hover a{width:100%;background-color:#F2F3FA;border-radius:10px}.sidebar__btn{position:absolute;bottom:0;width:200px;height:35px;margin-bottom:15px}.sidebar__sub-menu{width:200px;height:110px;padding:5px;border-radius:10px;font-family:"Poppins",sans-serif;font-size:12px;box-shadow:1px 4px 8px 1px rgba(17,17,19,0.1)}

.info-card{width:200px;height:150px;margin-top:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.info-card h3{margin-top:50px;text-align:center;font-size:16px;font-weight:600;color:#363636}.info-card p{text-align:center;font-size:12px;color:#686CD4}

.filter-period{width:320px}.filter-period__container{display:flex}.filter-period__container .input,select{width:200px;background-color:transparent;border:1px solid rgba(0,0,0,0.407);padding:0 10px;font-size:12px}.input-options{height:40px}.filter-period__btn{width:100px;height:30px;margin-left:20px;margin-top:0px}

.notification{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.notification h2{padding:50px 30px 0;font-size:30px}.notification span{color:#53d5d5}.notification__container{padding:20px 30px}.notification h4{font-weight:400}

.notification-card{width:100%;height:130px;margin-top:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.notification-card h3{padding:15px 30px 0;font-size:20px;font-weight:400}.notification-card small{padding:0 30px}.notification-card__btn{padding:10px 30px}.notification__btn{width:80px;height:30px;background-color:transparent;border:1px solid #64BACC;background-color:transparent;color:#64BACC;box-shadow:none}.notification__btn:hover{color:#fff;background-color:#64BACC}

.calendario{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:hidden}.calendario h2{padding:50px 30px 0;font-size:30px}.calendario-form{padding:20px 30px 0}.calendario-form .btn{width:100px;color:white;font-weight:200px}.calendario-span{color:#53d5d5}.calendario-container{margin:0px;height:500px}.calendario button{height:40px;width:60px;font-family:"Poppins",sans-serif;font-size:10px;border:none;border-radius:10px;color:#6100DD;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2)}

.evento{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:hidden}.evento h2{padding:50px 30px 0;font-size:30px}.evento__container{padding:0px 250px}.evento input{height:40px}.evento__btn button{margin-top:20px;height:40px}

.informes{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.informes h2{padding:50px 30px 0;font-size:30px}.informes span{color:#53d5d5}.informes h3{font-size:20px;font-weight:600}.informes__container{margin:0 30px 20px}.informes__search{margin-bottom:20px}table{text-align:center;width:100%}th{font-size:16px;font-weight:400}td{font-size:13px}tr:nth-child(even){background-color:#ffff;height:50px}tr:nth-child(odd){background-color:#F2F3FA;height:50px}.tb__container{display:flex;align-items:center;justify-content:center;margin-top:10px}.tb__btn{width:80px;height:30px;margin-right:10px;background-color:#6100DD}.tb__btn--alt{width:80px;height:30px;background-color:#F57F79}.tb__btn--alt:hover{background-color:#F57F79}

.documentos__nav{display:flex;flex-wrap:wrap;justify-content:left;padding:50px 30px}.documentos__nav p{margin:0 25px 0 0px;font-size:16px;font-weight:800;text-align:center;color:#3F3D3D;cursor:pointer}.documentos__nav p:hover{color:#6100DD;border-bottom:2px solid #6100DD}

.documentos{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.documentos h2{padding:50px 30px 0;font-size:30px}.documentos span{color:#53d5d5}.documentos h3{font-size:20px;font-weight:600}.documentos__container{margin:0 30px 20px}.documentos__search{margin-bottom:20px}table{text-align:center;width:100%}th{font-size:16px;font-weight:400}td{font-size:13px}tr:nth-child(even){background-color:#ffff;height:50px}tr:nth-child(odd){background-color:#F2F3FA;height:50px}.tb__container{display:flex;align-items:center;justify-content:center;margin-top:10px}.tb__btn{width:80px;height:30px;margin-right:10px;background-color:#6100DD}.tb__btn--alt{width:80px;height:30px;background-color:#F57F79}.tb__btn--alt:hover{background-color:#F57F79}

.actas{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.actas h2{padding:50px 30px 0;font-size:30px}.actas span{color:#53d5d5}.actas h3{font-size:20px;font-weight:600;cursor:pointer}.actas__container{margin:0 30px 20px}.actas__card{display:flex;flex-wrap:wrap;align-items:center;justify-content:flex-start}

.protocolos{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.protocolos h2{padding:50px 30px 0;font-size:30px}.protocolos span{color:#53d5d5}.protocolos h3{font-size:20px;font-weight:600;cursor:pointer}.protocolos__container{margin:0 30px 20px}.protocolos__card{display:flex;flex-wrap:wrap;align-items:center;justify-content:flex-start}

.archivos{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.archivos h2{padding:50px 30px 0;font-size:30px}.archivos__form{padding-top:50px}.archivos__container{height:80%;margin:20px 100px;padding:80px 250px;text-align:center;border-radius:10px;background-color:#ffff;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2)}.archivos__input{height:50px;width:100%;margin-top:20px;background-color:#efefef;border:none}.archivos__upload{width:100%;height:60px;margin-top:20px;background-color:#efefef;border-radius:10px;background-color:#53d5d5;text-align:center;cursor:pointer}.archivos__upload p{margin-top:-40px;padding:0px 0;font-size:14px;font-weight:600}.archivos__file{margin-top:-100px;width:100%;height:60px;opacity:0}.archivos__upload small{font-size:10px}.archivos__btn{width:100%;height:50px;margin-top:60px}.archivos span{color:#53d5d5}.archivos__container--btn{display:flex;justify-content:right;width:20%}

.analisis{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.analisis h2{padding:50px 30px 0;font-size:30px}.analisis span{color:#53d5d5}.analisis h3{font-size:20px;font-weight:600}.analisis__container{margin:0 30px 20px}.analisis__search{margin-bottom:20px}table{text-align:center;width:100%}th{font-size:16px;font-weight:400}td{font-size:13px}tr:nth-child(even){background-color:#ffff;height:50px}tr:nth-child(odd){background-color:#F2F3FA;height:50px}.tb__container{display:flex;align-items:center;justify-content:center;margin-top:10px}.tb__btn{width:80px;height:30px;margin-right:10px;background-color:#6100DD}.tb__btn--alt{width:80px;height:30px;background-color:#F57F79}.tb__btn--alt:hover{background-color:#F57F79}

.soporte{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.soporte h2{padding:50px 30px 0;font-size:30px}.soporte span{color:#53d5d5}.soporte__container{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:left;padding:0px 30px}.soporte__container h3{padding-top:40px;font-size:30px}.soporte .info-card{margin-right:50px}

.iaas{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.iaas h2{padding:50px 30px 0;font-size:30px}.iaas span{color:#53d5d5}.iaas__btn{width:120px;height:30px;margin-left:30px;font-size:10px}.iaas h4{padding:10px 30px;font-size:16px}.iaas p{padding:0px 30px 0}.iaas .filter-period{padding-left:30px}.iaas__info{padding:15px 30px;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start}.iaas__graph-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.iaas__graph{width:510px;height:300px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.iaas__card-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.iaas__card{width:350px;height:220px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.iaas__graph-title h5{margin-bottom:5px;font-size:16px;font-weight:600}.iaas__table-container{padding:20px 30px}.iaas__table{width:100%;height:400px;padding:10px;background-color:#fff;border-radius:20px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.iaas__table-container h3{margin-bottom:5px;font-weight:400}

.soporte{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.soporte h2{padding:50px 30px 0;font-size:30px}.soporte span{color:#53d5d5}.soporte__container{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:left;padding:0px 30px}.soporte__container h3{padding-top:40px;font-size:30px}.soporte .info-card{margin-right:50px}

.epidemiolgia{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.epidemiolgia h2{padding:50px 30px 0;font-size:30px}.epidemiolgia .epidemiolgia__container{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:left;padding:30px}.epidemiolgia .info-card{margin-right:50px;padding-top:1px}

.microorganismos{height:170vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.microorganismos h2{padding:50px 30px 0;font-size:30px}.microorganismos span{color:#53d5d5}.microorganismos h4{padding:10px 30px;font-size:20px}.microorganismos .filter-period{padding-left:30px}.microorganismos__info{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;padding:0px 30px}.microorganismos__graph-container{padding:20px 30px}.microorganismos__graph-title h3{margin-bottom:5px;font-weight:400}.microorganismos__graph{width:450px;height:400px;padding:10px;background-color:#fff;border-radius:20px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.microorganismos__graph-container--alt{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.microorganismos__graph--alt{width:500px;height:400px;padding:10px;background-color:#fff;border-radius:20px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1);overflow:scroll}.microorganismos__graph--alt2{width:300px;height:220px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.microorganismos__graph--alt{width:450px;height:400px;padding:10px;background-color:#fff;border-radius:20px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1);overflow:scroll}

.filter-period{width:320px}.filter-period__container .input,select{display:flex;justify-content:flex-start}.input-options{height:30px}.filter-period__btn{width:100px;height:30px;margin-left:20px;margin-top:0px}

.antibioticos{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.antibioticos h2{padding:50px 30px 0;font-size:30px}.antibioticos span{color:#53d5d5}.antibioticos h4{margin-bottom:30px;font-size:24px;font-weight:600}.antibioticos__container{margin:0 30px 20px}

.options__nav{display:flex;flex-wrap:wrap;justify-content:left;font-family:"Poppins",sans-serif}.options__nav p{font-size:1px;font-weight:200;text-align:center;color:#3F3D3D;cursor:pointer;border-bottom:1px solid rgba(128,128,128,0.179)}.options__nav p:hover{color:#6100DD;border-bottom:2px solid #6100DD}

.costos{height:100vh;padding-left:250px;padding-top:50px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.costos h2{padding:0px 30px 0;font-size:30px}.costos__container{padding:0px 30px}.costos__container h2{padding:0;font-size:24px;font-weight:600}.costos__container h4{padding:10px 0px;font-size:20px;font-weight:600}.costos__card{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start}.costos__graph{width:510px;height:300px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.costos__graph-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 0px}

.filter-period{width:320px}.filter-period__container .input,select{display:flex;justify-content:flex-start}.input-options{height:30px}.filter-period__btn{width:100px;height:30px;margin-left:20px;margin-top:0px}

.demo{height:210vh;padding-left:250px;padding-top:50px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.demo h2{padding:50px 30px 0;font-size:30px}

.servicios{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.servicios h2{padding:50px 30px 0;font-size:30px}.servicios span{color:#53d5d5}.servicios__container{padding:0px 30px}.servicios__container h2{padding:40px 0px;font-size:20px}.servicios__container h4{font-size:16px}.servicios__card{padding:15px 0px;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center}.servicios__graph-container{padding-bottom:10px;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center}.servicios__graph{width:510px;height:300px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.servicios__card-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.servicios__graph-title h5{margin-bottom:5px;font-size:16px;font-weight:600}

.filter-period{width:320px}.filter-period__container .input,select{display:flex;justify-content:flex-start}.input-options{height:30px}.filter-period__btn{width:100px;height:30px;margin-left:20px;margin-top:0px}

.service__nav{display:flex;flex-wrap:nowrap;justify-content:left;padding:50px 30px;font-family:"Poppins",sans-serif;overflow:scroll}.service__nav p{margin:0 25px 0 0px;font-size:16px;font-weight:800;text-align:center;color:#3F3D3D;cursor:pointer}.service__nav p:hover{color:#6100DD;border-bottom:2px solid #6100DD}

.listas{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4;overflow:scroll}.listas h2{padding:50px 30px 0;font-size:30px}.listas span{color:#53d5d5}.listas h4{padding:10px 30px;font-size:16px}.listas p{padding:0px 30px 0}.listas .filter-period{padding-left:30px}.listas__info{padding:15px 30px;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start}.listas__graph-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.listas__graph{width:510px;height:300px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.listas__card-container{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:20px 30px}.listas__card{width:350px;height:220px;padding:10px;background-color:#fff;border-radius:10px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.listas__graph-title h5{margin-bottom:5px;font-size:16px;font-weight:600}.listas__table-container{padding:20px 30px}.listas__table{width:100%;height:400px;padding:10px;background-color:#fff;border-radius:20px;box-shadow:1px 4px 8px 1px rgba(48,47,55,0.1)}.listas__table-container h3{margin-bottom:5px;font-weight:400}

.options__nav{display:flex;flex-wrap:wrap;justify-content:left;padding:50px 30px;font-family:"Poppins",sans-serif}.options__nav p{margin:0 50px 0 0px;font-size:20px;font-weight:800;text-align:center;color:#3F3D3D;cursor:pointer}.options__nav p:hover{color:#6100DD;border-bottom:2px solid #6100DD}

.perfil{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.perfil h2{padding:50px 30px 0;font-size:30px}.perfil span{color:#53d5d5}.perfil__container{margin:0 150px}.perfil__container .input__container{font-family:"Poppins",sans-serif}.perfil__container input,.perfil__container select{margin:10px 0;width:100%;height:60px;padding:0 12px;font-family:"Lato",sans-serif;font-size:16px;border:1px solid #b8b8b8;border-radius:10px;color:#1A171B;background-color:#F2F3FA}.perfil__container input:-ms-input-placeholder{padding:0 12px;font-size:16px;font-family:"Lato",sans-serif;color:rgba(114,113,113,0.796078)}.perfil__container input::placeholder{padding:0 12px;font-size:16px;font-family:"Lato",sans-serif;color:rgba(114,113,113,0.796078)}

.crear-usuario{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.crear-usuario h2{padding:50px 30px 0;font-size:30px}.crear-usuario span{color:#53d5d5}.crear-usuario__container{padding:0 250px}.crear-usuario__container input{height:40px}.crear-usuario__select{margin-top:20px}.crear-usuario__container select{height:40px;width:100%}.profile__btn{margin-top:30px;font-size:14px;height:40px}

.soporte{height:100vh;padding-left:250px;font-family:"Poppins",sans-serif;background-color:#F4F4F4}.soporte h2{padding:50px 30px 0;font-size:30px}.soporte span{color:#53d5d5}.soporte__container{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:left;padding:0px 30px}.soporte__container h3{padding-top:40px;font-size:30px}.soporte .info-card{margin-right:50px}

